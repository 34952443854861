import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.scss';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { Stack, Typography } from '@mui/material';

const Home = () => {
    return (
        <Stack height="100vh" width="fit-content" direction="row" alignItems="center" ml="auto" mr="auto">
            <ReportProblemOutlinedIcon sx={{ fontSize: 50 }} />
            <Typography variant="h3" >
                ERROR
            </Typography>
        </Stack>
    )
};

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
